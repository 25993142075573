import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import computer from "../images/computer.jpg"
import Accolades from "../components/sections/Accolades"
import MiniContent from "../components/sections/MiniContent"
import {
  PlayIcon,
} from '@heroicons/react/24/outline'
import IndexNavbar from "../components/navigation/IndexNavbar"
import Footer from "../components/navigation/Footer"

import family from '../images/family.jpeg'
import Button from "../components/elements/Button"

const features = [
  {
    title: 'Independent',
    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, veniam.',
    href: '/',

  },
  {
    title: 'Team Based',
    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, veniam.',
    href: '/',
  },
  {
    title: 'Something',
    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, veniam.',
    href: '/',
  },
  {
    title: 'Else',
    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium, veniam.',
    href: '/',
  },
]

const IndexPage = () => (
  <div>
    <IndexNavbar />
    <Seo title="Home" />
    <section className="lg:-mt-24">
      {/* <img src={computer} /> */}
      <div className="grid bg-gray-100">
        <StaticImage
          src='../images/computer.jpg'
          style={{ gridArea: "1/1" }}
          layout="fullWidth"
          aspectRatio={2 / 1}
          alt=""
          className="filter grayscale"
        />
        {/* <img src={computer} /> */}

        <div
          // style={{ gridArea: "1/1", position: "relative", placeItems: "center", display: "grid", }}
          style={{ gridArea: "1/1", position: "relative", placeItems: "center", display: "grid", }}
          className="text-white bg-blue-500/75"
        >
          <div className="body-font">
            {/* <div className="container mx-auto py-20 text-center justify-items-center items-center place-items-center"> */}
            <div className="container mx-auto xl:grid grid-cols-2 grid-rows-2 text-center xl:text-left">
              <h1 className="text-white tracking-tight text-5xl lg:text-6xl xl:text-8xl flex-wrap">Wealth Has Purpose</h1>
              <div className="">
                <h4 className="text-white font-extralight text-2xl xl:text-4xl leading-10 tracking-wide">Roseman Wealth Advisors exists to help individuals and families <span className="font-medium">realize</span> and <span className="font-medium">fulfil</span> that purpose.</h4>
                <Link to="" className="group text-2xl leading-10 font-light inline-flex items-center text-gray-200 hover:text-gray-100 pt-6 hover:scale-105 active:scale-100">
                  What to Expect
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2 group-hover:translate-x-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>

            </div>

          </div>

        </div>
        {/* video */}
        <div className="lg:grid grid-cols-5">
          <div className="col-start-2 col-span-3 bg-gray-900 py-32 lg:rounded-lg lg:-mt-48 lg:relative">
            <PlayIcon className="flex-shrink-0 h-48 w-48 text-blue-500 mx-auto" />
          </div>
        </div>

        {/* Our Company */}
        <div className="container mx-auto mt-24">

          {/* <h1 className="text-gray-900 font-serif font-bold text-4xl">Our Story</h1>

          <div className="pt-6">
            <p className="text-2xl font-thin text-gray-800">
              We are a team-based comprehensive wealth management company located in Tyler, Texas, whose professionals work with clients across the country. Our mission is to help individuals and families realize that the purpose for their wealth is to facilitate their life purpose, and then guide them towards the fulfillment of that purpose in all areas of their financial life. We start with a financial plan and support that plan with sound investment management, insurance planning, and tax planning.
            </p>
          </div> */}

          <p className="text-3xl lg:text-7xl text-gray-900 font-serif font-bold text-center">
            We are a <span className="text-blue-500">team-based comprehensive wealth management</span> company located in Tyler, Texas whose professionals work with clients across the country.
          </p>
        </div>

        <div className="border-b-2 border-gray-300 py-12 container mx-auto"></div>

        {/* Our Services  */}
        <div className="container mx-auto mt-24 pb-12">
          <h1 className="text-gray-900 font-serif font-bold text-4xl">Our Company</h1>
          <p className="text-2xl font-thin text-gray-800 lg:w-1/2 mt-6">
            We are a team-based comprehensive wealth management company located in Tyler, Texas, whose professionals work with clients across the country.
          </p>

          <Link to="#" className="text-blue-500 mt-4 inline-flex items-center">Our Philosophy
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>

          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4 my-12">
            {features.map((feature) => (
              <div className="bg-white rounded hover:shadow-xl p-4">
                <img className="object-cover object-center content-center" src="https://dummyimage.com/150x150" />
                <h2 className="mt-4 font-serif font-bold">{feature.title}</h2>
                <p className="mt-4 text-gray-800">{feature.description}</p>
                <Link to="#" className="text-blue-500 mt-4 inline-flex items-center">Learn More
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </Link>
              </div>
            ))}

          </div>
        </div>


      </div>
    </section>
    <MiniContent />

    <div className="h-screen bg-gray-100"></div>


    <Footer />
  </div>
)

export default IndexPage
